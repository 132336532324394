import React, { useState } from 'react'
import axios from 'axios'

function App() {
  const [email, setEmail] = useState({emailIds:'', subject: '', body: '', password: ''});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');

  const handleSubmit = (e)=>{
    e.preventDefault();
    setSuccess('');
    setFailure('');
    setLoading(true);

    axios.post('https://email.finocontrol.com/api/send-bulk-emails', email).then(res=>{
      console.log(res);
      setSuccess(res.data.message);
      setEmail({emailIds:'', subject: '', body: '', password: ''});
    }).catch(err=>{
      setFailure(err?.response?.data?.message);
    }).finally(()=>{
      setLoading(false);
    })
  }

  const clearFormData= (e)=>{
    e.preventDefault();
    setSuccess('');
    setFailure('');
    setLoading(false);
    setEmail({emailIds:'', subject: '', body: '', password: ''});
  }
  
  return (
    <div className='container tw-h-screen'>
      <span className='tw-flex tw-justify-center tw-font-semibold tw-text-lg md:tw-text-xl lg:tw-text-3xl tw-pt-8'>Finocontrol - Bulk email sender</span>
      <form onSubmit={handleSubmit} onReset={clearFormData} className='md:tw-w-[300px] lg:tw-w-[500px]'>
      <div className="tw-form-control">
        <label className="tw-label">
          {/* <span className="tw-label-text">Paste All Email id's separated by commas(,)</span> */}
        </label>
        <label className="tw-input-group">
          <span className='tw-text-xs md:tw-text-sm lg:tw-text-base tw-font-medium'>IDS</span>
          <input autoFocus value={email.emailIds} onChange={e=> setEmail({...email, emailIds: e.target.value})} type="text" placeholder="info1@site.com, info2@site.com" className="tw-input tw-w-full tw-input-bordered" />
        </label>
      </div>

      <div className="tw-form-control tw-py-2">
        <label className="tw-label">
          {/* <span className="tw-label-text">Subject of your email</span> */}
        </label>
        <label className="tw-input-group">
          <span className='tw-text-xs md:tw-text-sm lg:tw-text-base tw-font-medium'>Subject</span>
          <input value={email.subject} onChange={e=> setEmail({...email, subject: e.target.value})} type="text" placeholder="Subject" className="tw-input tw-w-full tw-input-bordered" />
        </label>
      </div>

      <div className="tw-form-control tw-py-2">
        <label className="tw-label">
          {/* <span className="tw-label-text">Subject of your email</span> */}
        </label>
        <label className="tw-input-group">
          <span className='tw-min-w[50px] md:tw-min-w-[120px] tw-text-xs md:tw-text-sm lg:tw-text-base tw-font-medium'>HTML body</span>
          <textarea className="tw-textarea tw-w-full tw-textarea-bordered tw-textarea-md" placeholder='HTML Body' value={email.body} onChange={e=> setEmail({...email, body: e.target.value})} rows={5}/>
        </label>
      </div>

      <div className="tw-form-control tw-py-2">
        <label className="tw-label">
          {/* <span className="tw-label-text">Subject of your email</span> */}
        </label>
        <label className="tw-input-group">
          <span className='tw-text-xs md:tw-text-sm lg:tw-text-base tw-font-medium'>Password</span>
          <input value={email.password} onChange={e=> setEmail({...email, password: e.target.value})} type="password" placeholder="Password" className="tw-input tw-w-full tw-input-bordered" />
        </label>
      </div>

      {success!== "" && <p className='tw-font-bold tw-text-sm tw-text-green-500'>Success: {success}</p>}
      {failure!== "" && <p className='tw-font-bold tw-text-sm tw-text-red-500'  >Failure: {failure}</p>}
      <br/>
      <div>
        <button className='tw-mr-4 tw-btn tw-btn-outline tw-btn-primary tw-w-[100px]' type='submit' disabled={loading} >Submit</button>
        <button className='tw-btn tw-btn-outline tw-btn-warning  tw-w-[100px]' type='reset' disabled={loading} >Reset</button>
      </div>
    </form>
    </div>
  )
}

export default App
